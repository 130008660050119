/**
 * @generated SignedSource<<a34e51b332aa2e1dc8870e2cb1d5f2a5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type WellbeingProfileFocusAreas = "EMOTIONAL" | "FINANCIAL" | "PHYSICAL" | "PROFESSIONAL" | "SLEEP";
import { FragmentRefs } from "relay-runtime";
export type WellbeingProfileScoreDisplay_wellbeingProfileResults$data = ReadonlyArray<{
  readonly createdAt: string;
  readonly scores: ReadonlyArray<{
    readonly answers: ReadonlyArray<{
      readonly answer: string;
      readonly question: string;
    }>;
    readonly focusArea: WellbeingProfileFocusAreas;
    readonly score: number;
  }>;
  readonly wellbeingScore: number;
  readonly " $fragmentType": "WellbeingProfileScoreDisplay_wellbeingProfileResults";
}>;
export type WellbeingProfileScoreDisplay_wellbeingProfileResults$key = ReadonlyArray<{
  readonly " $data"?: WellbeingProfileScoreDisplay_wellbeingProfileResults$data;
  readonly " $fragmentSpreads": FragmentRefs<"WellbeingProfileScoreDisplay_wellbeingProfileResults">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "WellbeingProfileScoreDisplay_wellbeingProfileResults",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "wellbeingScore",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WellbeingProfileResultScore",
      "kind": "LinkedField",
      "name": "scores",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "focusArea",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "score",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "WellbeingProfileResultAnswers",
          "kind": "LinkedField",
          "name": "answers",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "question",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "answer",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WellbeingProfileResult",
  "abstractKey": null
};

(node as any).hash = "019ac4ea63323c2e0f6411a66d6e477b";

export default node;
