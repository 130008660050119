import ctl from "@netlify/classnames-template-literals";

export const wellbeingProfileGetMatchedBannerWrapper = ctl(`
    p-2 
    rounded-2xl 
    bg-gradient-to-br 
    from-status-teal-500/30 
    to-status-teal-50/30
    w-full
    mt-3
`);

export const overallTabContentWrapper = ctl(`
    w-full 
    animate-fade-in 
    flex 
    items-center 
    flex-col 
    gap-y-5
`);

export const overallTimeToggleWrapper = ctl(`
    peer 
    flex 
    gap-x-20
    h-10
    items-center 
    rounded-full 
    bg-purple-600
    px-10
    [&>p]:z-30
    
    after:absolute 
    after:left-0
    after:h-10
    after:w-[9.3rem]
    after:rounded-full 
    after:bg-white 
    after:transition-all 
    
    peer-checked:after:translate-x-full 
`);

export const centeredOverallToggleWrapper = ctl(`
    hidden 
    transform 
    lg:flex 
    xl:absolute 
    xl:left-1/2 
    xl:-translate-x-1/2
`);
