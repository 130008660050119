import { Disclosure, Progress, Text } from "@olivahealth/oli-ui";
import useTranslation from "../../../hooks/useTranslation";

interface Props {
  focusArea: string;
  score: number;
  hasPadding?: boolean;
  answers?: readonly { question: string; answer: string }[];
  contentToShow?: "answers" | "description";
  variant?: "light" | "dark";
}

export default function FocusAreaScoreDisclosure({
  hasPadding,
  focusArea,
  score,
  answers = [],
  contentToShow = "description",
  variant = "light",
}: Props) {
  const { t } = useTranslation("wellbeingProfile");

  const mainTextColor = variant === "light" ? "white" : "primary";
  const secondaryTextColor = variant === "light" ? "white" : "secondary-light";

  return (
    <Disclosure
      padding={hasPadding ? "md" : "none"}
      {...(variant === "light" ? { chevronColor: "white" } : {})}
      chevronVerticalAlign="top"
      variant="ghost"
      headerComponent={
        <div className="flex flex-col gap-y-2 w-full">
          <Text color={mainTextColor} weight="bold" size="sm">
            {t(`focusAreas.${focusArea}`)}
          </Text>
          <Progress
            variant={variant === "light" ? "light" : "default"}
            percent={score}
            label={`${Math.ceil(score)}%`}
            labelPosition="top"
          />
        </div>
      }
    >
      <div className={`${hasPadding ? "" : "py-4"}`}>
        {contentToShow === "description" ? (
          <Text color={mainTextColor}>
            {t(`severitiesDescription.${focusArea}.${getSeverity(score)}`)}
          </Text>
        ) : (
          <div className="flex flex-wrap gap-4">
            {answers.map((answer) => {
              return (
                <div key={answer.question} className="flex flex-col gap-y-1">
                  <Text size="sm" weight="medium" color={secondaryTextColor}>
                    {answer.question}
                  </Text>
                  <Text weight="bold" color={mainTextColor}>
                    {answer.answer}
                  </Text>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Disclosure>
  );
}

function getSeverity(score: number) {
  if (score < 50) return "high";
  if (score < 70) return "medium";
  return "low";
}
