import { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";
import tokens from "@olivahealth/oli-ui/theme/tokens";
import { format, startOfMonth } from "date-fns";
import { WellbeingProfileScoreDisplay_wellbeingProfileResults$data } from "./__generated__/WellbeingProfileScoreDisplay_wellbeingProfileResults.graphql";

export default function OverTimeGraph({
  data,
  onItemClick,
}: {
  data: WellbeingProfileScoreDisplay_wellbeingProfileResults$data;
  onItemClick: (index: number) => void;
}) {
  const [activeDotIndex, setActiveDotIndex] = useState(0);

  const parsedData = data.map((wellbeingProfile) => ({
    date: new Date(wellbeingProfile.createdAt).getTime(),
    percentage: Math.ceil(wellbeingProfile.wellbeingScore),
  }));

  // We create the domain for the XAxis as well as the ticks being the first of every month;
  const ticks = createGraphTicks(parsedData);

  const CustomDot = (props: any) => {
    const { cx, cy, onClick, index } = props;

    const isSelected = activeDotIndex === index;
    return (
      <circle
        cx={cx}
        cy={cy}
        r={8}
        strokeWidth={4}
        stroke={isSelected ? tokens.colors.purple[500] : "white"}
        fill={isSelected ? "white" : tokens.colors.purple[500]}
        onClick={() => {
          setActiveDotIndex(index);
          onClick(index);
        }}
        style={{ cursor: "pointer" }}
      />
    );
  };

  return (
    <ResponsiveContainer width="100%" height={350}>
      <LineChart
        data={parsedData}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid horizontal={true} vertical={false} />
        <XAxis
          dataKey="date"
          tickFormatter={(date) => format(new Date(date), "MMMM yyyy")}
          tickMargin={15}
          type="number"
          domain={["dataMin", "dataMax"]}
          ticks={ticks}
          style={{
            fill: "white",
            stroke: "white",
          }}
        />
        <YAxis
          tickFormatter={(value) => `${value}%`}
          tickMargin={10}
          ticks={[0, 50, 100]}
          domain={[0, 100]}
          style={{
            fill: "white",
            stroke: "white",
          }}
        />
        <Line
          type="monotone"
          dataKey="percentage"
          animationDuration={500}
          strokeWidth={4}
          stroke={tokens.colors.purple[500]}
          activeDot={{ r: 8 }}
          dot={<CustomDot onClick={onItemClick} />}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

function createGraphTicks(
  parsedData: { date: number; percentage: number }[],
): number[] {
  const uniqueMonths = new Set<string>();

  parsedData.forEach((item) => {
    const date = new Date(item.date);
    const monthYear = format(date, "yyyy-MM");
    uniqueMonths.add(monthYear);
  });

  const ticks = Array.from(uniqueMonths).map((monthYear) => {
    const [year, month] = monthYear.split("-").map(Number);
    return startOfMonth(new Date(year, month - 1)).getTime();
  });

  ticks.sort((a, b) => a - b);
  const firstDate = parsedData?.[parsedData.length - 1]?.date;

  return [firstDate, ...ticks.slice(1)];
}
