import {
  Button,
  Card,
  CrossIcon,
  Dialog,
  InfoCircleIcon,
  Text,
  Tooltip,
} from "@olivahealth/oli-ui";
import { graphql, useFragment } from "react-relay";
import { useEffect, useState, useRef } from "react";
import { Transition } from "@headlessui/react";
import { useRouter } from "next/router";
import WellbeingProfileFocusAreas from "@olivahealth/graphql-server/src/domain/value-objects/WellbeingProfileFocusAreas";
import {
  ABOVE_CASENESS_SCORE_THRESHOLD,
  BELOW_CASENESS_SCORE_THRESHOLD,
  RECOMMEND_THERAPY_FOCUS_AREAS,
} from "@olivahealth/constants";
import { formatMinimalDateFullMonthYear } from "../../../utils/date/format";
import Image from "../../../components/common/Image";
import useTranslation, { TFunction } from "../../../hooks/useTranslation";
import FocusAreaScoreDisclosure from "../../molecules/FocusAreaScoreDisclosure";
import WellbeingProfileGasTank from "../../../components/common/WellbeingProfileGasTank/WellbeingProfileGasTank";
import { useSharedStoreContext } from "../../../services/contexts/SharedStoreContext";
import * as s from "./styles";
import styles from "./styles.module.css";
import {
  WellbeingProfileScoreDisplay_wellbeingProfileResults$key,
  WellbeingProfileScoreDisplay_wellbeingProfileResults$data,
} from "./__generated__/WellbeingProfileScoreDisplay_wellbeingProfileResults.graphql";
import CurrentScoreBreakdownDialog from "./CurrentScoreBreakdownDialog";
import OverTimeGraph from "./OvertimeGraph";

interface Props {
  wellbeingProfileResults: WellbeingProfileScoreDisplay_wellbeingProfileResults$key;
  showStartCareCard: boolean;
  focusArea: WellbeingProfileFocusAreas | null;
  userName?: string;
  showUpdateButton?: boolean;
}

interface OverallOverTimeToggleProps {
  toggleMode: () => void;
  t: TFunction;
  mode: "overall" | "overTime";
}

interface OverallTabContentProps {
  userName?: string;
  t: TFunction;
  data: WellbeingProfileScoreDisplay_wellbeingProfileResults$data[0];
  wellbeingScore: number;
  showStartCareCard: boolean;
  setShowBreakdownAreas: (value: boolean) => void;
  focusArea: WellbeingProfileFocusAreas | null;
}

interface OverTimeTabContentProps {
  t: TFunction;
  data: WellbeingProfileScoreDisplay_wellbeingProfileResults$data;
  Trans: any;
  hasExternalUserName: boolean;
}

const WellbeingProfileScoreDisplayFragment = graphql`
  fragment WellbeingProfileScoreDisplay_wellbeingProfileResults on WellbeingProfileResult
  @relay(plural: true) {
    createdAt
    wellbeingScore
    scores {
      focusArea
      score
      answers {
        question
        answer
      }
    }
  }
`;

export default function WellbeingProfileScoreDisplay({
  wellbeingProfileResults,
  showStartCareCard,
  focusArea,
  userName,
  showUpdateButton = true,
}: Props) {
  const data =
    useFragment<WellbeingProfileScoreDisplay_wellbeingProfileResults$key>(
      WellbeingProfileScoreDisplayFragment,
      wellbeingProfileResults,
    );
  const hasHiddenContent = useRef(false);
  const { surveyEmployeeNPS } = useSharedStoreContext();
  const hasSeenSurvey = surveyEmployeeNPS.hasSeenSurvey === true;
  const currentData = data[0];
  const { t, Trans } = useTranslation("wellbeingProfile");
  const [showContent, setShowContent] = useState(true);
  const [mode, setMode] = useState<"overall" | "overTime">("overall");
  const [showBreakdownAreas, setShowBreakdownAreas] = useState(false);
  const showToggle = data.length > 1 && showContent;

  const wellbeingScore = Math.ceil(currentData.wellbeingScore);

  useEffect(() => {
    if (hasSeenSurvey && !hasHiddenContent.current) {
      setShowContent(false);
      hasHiddenContent.current = true;
    }
  }, [hasSeenSurvey, setShowContent]);

  const toggleMode = () => {
    setMode((mode) => (mode === "overall" ? "overTime" : "overall"));
  };

  return (
    <Card
      rounding="2xl"
      padding="lg"
      border={false}
      backgroundClassName="bg-neutral-900"
    >
      <CurrentScoreBreakdownDialog
        t={t}
        isExternal={Boolean(userName)}
        data={currentData}
        isOpen={showBreakdownAreas}
        onClose={() => setShowBreakdownAreas(false)}
        showUpdateButton={showUpdateButton}
      />

      <div className="flex flex-col w-full items-center">
        <div className="flex w-full flex-col mb-3 gap-y-2">
          <span className="flex items-center justify-between">
            <span className="flex gap-x-2 items-center">
              <Text color="white" weight="bold" size="xl">
                {t(
                  userName
                    ? "scoreDisplay.titleExternal"
                    : "scoreDisplay.yourWellbeingProfile",
                )}
              </Text>
              <Tooltip
                placement="top"
                variant="light"
                text={t(
                  userName
                    ? "scoreDisplay.externalWellbeingProfileTooltip"
                    : "scoreDisplay.yourWellbeingProfileTooltip",
                )}
              >
                <InfoCircleIcon size={22} color="white" />
              </Tooltip>
            </span>

            {showToggle && (
              <div className={s.centeredOverallToggleWrapper}>
                <OverallOverTimeToggle
                  mode={mode}
                  t={t}
                  toggleMode={toggleMode}
                />
              </div>
            )}

            <button onClick={() => setShowContent((content) => !content)}>
              <Text color="accent-lighter" size="sm">
                {showContent ? t("scoreDisplay.hide") : t("scoreDisplay.show")}
              </Text>
            </button>
          </span>

          <Text color="secondary">
            {t("scoreDisplay.lastUpdated", {
              date: formatMinimalDateFullMonthYear(currentData.createdAt),
            })}
          </Text>

          {showToggle && (
            <div className="flex lg:hidden w-full justify-center my-2">
              <span>
                <OverallOverTimeToggle
                  mode={mode}
                  t={t}
                  toggleMode={toggleMode}
                />
              </span>
            </div>
          )}
        </div>
        <Transition
          show={showContent}
          {...transitionProps}
          className="w-full items-center gap-y-3 flex flex-col"
        >
          {mode === "overall" && (
            <OverallTabContent
              userName={userName}
              showStartCareCard={showStartCareCard}
              t={t}
              focusArea={focusArea}
              data={currentData}
              wellbeingScore={wellbeingScore}
              setShowBreakdownAreas={setShowBreakdownAreas}
            />
          )}
          {mode === "overTime" && (
            <OverTimeTabContent
              hasExternalUserName={Boolean(userName)}
              Trans={Trans}
              t={t}
              data={data}
            />
          )}
        </Transition>
      </div>
    </Card>
  );
}

function OverTimeTabContent({
  hasExternalUserName,
  data,
  t,
  Trans,
}: OverTimeTabContentProps) {
  const [dataIndex, setDataIndex] = useState(0);
  const currentData = data[dataIndex];
  const [showHowDoesThisWork, setShowHowDoesThisWork] = useState(false);

  const wellbeingScore = Math.ceil(currentData.wellbeingScore);

  return (
    <div className="flex animate-fade-in w-full items-center mt-3 flex-col">
      <Dialog
        isOpen={showHowDoesThisWork}
        variant="centered"
        onClose={() => setShowHowDoesThisWork(false)}
        title={t("resultsScreen.howDoesThisWorkModal.title")}
      >
        <Text>
          <Trans
            i18nKey={t("resultsScreen.howDoesThisWorkModal.description")}
          />
        </Text>
      </Dialog>
      <div className="relative mb-5 max-w-xl">
        <Image
          src="/img/img_progress_arc.png"
          alt=""
          fill
          sizes="100vw"
          style={{
            objectFit: "fill",
          }}
        />
        {!hasExternalUserName && (
          <div className="flex items-center flex-col [&>*]:z-10">
            <Text size="xl" color="white" gutter="sm" weight="bold">
              {t("scoreDisplay.overTimeTabContent.title")}
            </Text>
            <Text color="white" size="sm" align="center" gutter="lg">
              {t("scoreDisplay.overTimeTabContent.description")}
            </Text>
            <Button
              variant="link"
              className="mb-5"
              onClick={() => setShowHowDoesThisWork(true)}
            >
              <Text variant="link" color="accent-lighter">
                {t("scoreDisplay.overTimeTabContent.howDoesThisWork")}
              </Text>
            </Button>
          </div>
        )}
      </div>
      <div className="gap-y-10 w-full flex-col md:flex-row flex gap-x-5">
        <OverTimeGraph onItemClick={setDataIndex} data={data} />
        <div className="flex flex-col w-full">
          <div className="mb-3 ">
            <Text color="success-light" weight="bold" gutter="sm">
              {dataIndex === 0
                ? t(
                    "scoreDisplay.overTimeTabContent.currentWellbeingScoreTitle",
                  )
                : t("scoreDisplay.overTimeTabContent.dateWellbeingScoreTitle", {
                    date: formatMinimalDateFullMonthYear(currentData.createdAt),
                  })}
            </Text>
            <span className="flex items-center justify-between mb-3">
              <Text color="white" weight="bold">
                {t(
                  `scoreDisplay.overTimeTabContent.scoreTitle.${getScoreTitle(wellbeingScore)}`,
                )}
              </Text>
              <Text color="white" size="sm">
                {`${wellbeingScore}%`}
              </Text>
            </span>
          </div>
          <Text color="accent-lighter" size="sm" gutter="md">
            {t("scoreDisplay.overTimeTabContent.areaBreakdownTitle")}
          </Text>
          <div className="flex flex-col gap-y-7">
            {currentData.scores.map(({ score, focusArea, answers }) => (
              <div key={focusArea} className="flex flex-col">
                <FocusAreaScoreDisclosure
                  answers={answers}
                  focusArea={focusArea}
                  score={score}
                  contentToShow={
                    hasExternalUserName ? "answers" : "description"
                  }
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function OverallTabContent({
  t,
  data,
  wellbeingScore,
  setShowBreakdownAreas,
  showStartCareCard,
  focusArea,
  userName,
}: OverallTabContentProps) {
  const recommendationKeyPrefix = focusArea
    ? getRecommendationKeyPrefix(focusArea, wellbeingScore)
    : null;
  const { push } = useRouter();
  const [isBannerClosed, setIsBannerClosed] = useState(false);

  return (
    <div className={s.overallTabContentWrapper}>
      <WellbeingProfileGasTank
        userName={userName}
        percentage={data.wellbeingScore ?? 0}
      />
      <button onClick={() => setShowBreakdownAreas(true)}>
        <span className="flex gap-x-2 items-center">
          <Text color="white">{t("scoreDisplay.seeMore")}</Text>
          <Image
            width={24}
            height={24}
            src="/img/img_wellbeing_profile_areas.png"
            alt=""
          />
        </span>
      </button>
      <Transition
        show={showStartCareCard && !isBannerClosed && Boolean(focusArea)}
        {...transitionProps}
        className={`${s.wellbeingProfileGetMatchedBannerWrapper} ${styles.box} ${styles.tealBorder}`}
      >
        <button
          onClick={() => setIsBannerClosed(true)}
          className="absolute top-4 right-4"
        >
          <CrossIcon size={22} fill="white" />
        </button>
        <Text color="white" weight="bold" size="xl" gutter="sm">
          {t(`scoreDisplay.startCareBanner.${recommendationKeyPrefix}.title`)}
        </Text>
        <Text color="white" gutter="md">
          {t(
            `scoreDisplay.startCareBanner.${recommendationKeyPrefix}.description`,
          )}
        </Text>
        <div className="w-48">
          <Button
            width="full"
            variant="success"
            onClick={() => push("/triaging/start-care")}
          >
            {t("scoreDisplay.startCareBanner.cta")}
          </Button>
        </div>
      </Transition>
    </div>
  );
}

export const transitionProps = {
  enter: "transition ease duration-500 transform",
  enterFrom: "opacity-0 -translate-y-2",
  enterTo: "opacity-100 translate-y-0",
  leave: "transition ease duration-300 transform",
  leaveFrom: "opacity-100 translate-y-0",
  leaveTo: "opacity-0 -translate-y-2",
};

function getRecommendationKeyPrefix(
  focusArea: WellbeingProfileFocusAreas,
  wellbeingScore: number,
): string {
  if (wellbeingScore < ABOVE_CASENESS_SCORE_THRESHOLD) {
    return "therapistRecommended";
  }

  if (
    wellbeingScore >= ABOVE_CASENESS_SCORE_THRESHOLD &&
    wellbeingScore <= BELOW_CASENESS_SCORE_THRESHOLD
  ) {
    if (RECOMMEND_THERAPY_FOCUS_AREAS.includes(focusArea)) {
      return "therapistRecommended";
    }
  }

  return "coachRecommended";
}

function getScoreTitle(score: number): string {
  if (score < ABOVE_CASENESS_SCORE_THRESHOLD + 1) {
    return "low";
  }

  if (
    score >= ABOVE_CASENESS_SCORE_THRESHOLD + 1 &&
    score < BELOW_CASENESS_SCORE_THRESHOLD + 1
  ) {
    return "moderate";
  }

  return "high";
}

function OverallOverTimeToggle({
  toggleMode,
  t,
  mode,
}: OverallOverTimeToggleProps) {
  return (
    <label className="relative inline-flex cursor-pointer items-center">
      <input
        onChange={toggleMode}
        type="checkbox"
        checked={mode === "overTime"}
        className="peer sr-only"
      />
      <div className={s.overallTimeToggleWrapper}>
        <Text weight="bold" color={mode === "overall" ? "primary" : "white"}>
          {t("scoreDisplay.overallTitle")}
        </Text>
        <Text weight="bold" color={mode === "overTime" ? "primary" : "white"}>
          {t("scoreDisplay.overTimeTitle")}
        </Text>
      </div>
    </label>
  );
}
